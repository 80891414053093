import liveStreamIcon from "@assets/icons/liveIconRed.svg";
import { Button } from "@components/Button";
import { AddIcon, DownloadIcon } from "@components/Icons";
import { SearchInput } from "@components/SearchInput";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Button as MuiButton,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  styled
} from "@mui/material";
import { Box } from "@mui/system";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import {
  ModelOrganizationEvent,
  ModelSetting,
  useAdminCalendarGet,
  useAdminCalendarUploadStatusGet,
  useAdminSettingsGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useQueryClient } from "@tanstack/react-query";
import type {
  EventObject,
  ExternalEventTypes,
  Options
} from "@toast-ui/calendar";
import { TZDate } from "@toast-ui/calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import Calendar from "@toast-ui/react-calendar";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useRecoilValue } from "recoil";
import "./calendar.css";
import { CalendarEventCreateUpdate } from "./CalendarEventCreateUpdate";
import { CalendarEventView, getRRuleText } from "./CalendarEventView";
import { CalendarSearch } from "./CalendarSearch";
import { CustomProgressBar } from "@components/CustomProgressBar";
import { theme } from "./theme";
import "./calendar.css";

const StyledButtonGroup = styled(ToggleButtonGroup)(() => ({
  borderRadius: "99px",
  width: "304px",
  height: "40px",
  backgroundColor: "#E5E5E5",
  padding: "4px"
}));
const StyledButton = styled(ToggleButton)(() => ({
  borderRadius: "99px",
  width: "101px",
  border: "none",
  backgroundColor: "#E5E5E5",
  fontSize: "14px",
  cursor: "pointer",
  fontWeight: 600,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#FFF",
    borderRadius: "99px !important"
  },
  "&.Mui-selected": {
    backgroundColor: "#FFF !important",
    borderRadius: "99px !important",
    color: "#000"
  }
}));

const StyledMuiButton = styled(MuiButton)(({ theme }) => ({
  height: "40px",
  minWidth: "100px",
  fontSize: "14px",
  fontWeight: 600,
  border: "2px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: "15px 0 15px 0",
  textTransform: "capitalize",
  "&:hover": {
    opacity: 0.8
  }
}));
const today = new TZDate();

export const CalendarView = () => {
  const firstCalendarViewRender = useRef(true);
  const organizationId = useRecoilValue(organizationAtom);
  const navigate = useNavigate();
  const [selectedView, setSelectedView] = useState("month");
  const [searchOpen, setSearchOpen] = useState(false);
  const [eventDialogOpen, setEventDialogOpen] = useState(false);
  const [eventViewDialogOpen, setEventViewDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [refreshKey, setRefreshKey] = useState(0);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    delete: false,
    import: false
  });
  const [eventDialogDetails, setEventDialogDetails] = useState<{
    title: string;
    action: string;
    id: string;
    recurrenceId: string;
    view: string;
    isLiveStream: boolean;
    startDate: Date | number;
    endDate: Date | number;
  }>({
    title: "",
    action: "",
    id: "",
    recurrenceId: "",
    view: "",
    isLiveStream: false,
    startDate: new Date(),
    endDate: new Date()
  });
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) setSelectedView(newAlignment);
    setRefreshKey((prev) => prev + 1);
  };

  const [elapsedTime, setElapsedTime] = useState(0);
  const elapsedTimeRef = useRef<number | null>(null);
  const calendarRef = useRef<typeof Calendar>(null);
  const [selectedDateRangeText, setSelectedDateRangeText] = useState("");
  const [rangeStart, setRangeStart] = useState(today);
  const [rangeEnd, setRangeEnd] = useState(today);
  const initialCalendars: Options["calendars"] = [
    {
      id: "0",
      name: "Private",
      backgroundColor: "#1ABC9C",
      borderColor: "#1ABC9C",
      dragBackgroundColor: "#9e5fff"
    }
  ];
  const { data: status, refetch: statusRefetch } =
    useAdminCalendarUploadStatusGet({
      organizationId: organizationId!
    });

  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;

    const startTimer = () => {
      if (!elapsedTimeRef.current) {
        elapsedTimeRef.current = Date.now();
        setElapsedTime(0);
      }
      timerId = setInterval(() => {
        setElapsedTime(
          Math.floor((Date.now() - elapsedTimeRef.current!) / 1000)
        );
      }, 1000);
    };

    const stopTimer = () => {
      if (timerId) clearInterval(timerId);
      elapsedTimeRef.current = null;
    };

    const checkStatus = () => {
      if (
        status?.data?.status === "PROCESSING" ||
        status?.data?.status === "PENDING"
      ) {
        statusRefetch();
        if (!elapsedTimeRef.current) startTimer();
        timerId = setTimeout(checkStatus, 5000);
      } else {
        stopTimer();
      }
    };

    checkStatus();

    return () => {
      if (timerId) clearTimeout(timerId);
      stopTimer();
    };
  }, [status?.data?.status]);

  const getCalInstance = useCallback(
    () => calendarRef.current?.getInstance?.(),
    []
  );

  const formatElapsedTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
  };
  const [initialEvents, setInitialEvents] = useState<Partial<EventObject>[]>(
    []
  );

  const queryClient = useQueryClient();
  const preFetchedCalendarEvents:
    | {
        data: ModelOrganizationEvent[];
        status: number;
      }
    | undefined = queryClient.getQueryData(["CalendarEvents", organizationId]);
  useEffect(() => {
    if (
      preFetchedCalendarEvents &&
      preFetchedCalendarEvents?.data &&
      preFetchedCalendarEvents?.status == 200
    ) {
      const events: Partial<EventObject>[] = [];
      preFetchedCalendarEvents.data.map((event) => {
        event.recurrences!.map((recurrence) => {
          let rule = "Does not repeat";
          if (event.repeat) {
            rule = `Repeats ${getRRuleText(event.repeat)}`;
          }
          events.push({
            id: event.eventId,
            calendarId: 0,
            title: event.name,
            category: "time",
            start: new Date(recurrence.start!),
            end: new Date(recurrence.end!),
            isReadOnly: true,
            isVisible: true,
            // using raw for timezone
            raw: event.timezone,
            recurrenceRule: rule,
            // using isFocused for livestream flag
            isFocused: event.streamId ? true : false,
            //using dueDateClass for recurrenceId
            dueDateClass: recurrence.recurrenceId
          });
        });
      });
      setInitialEvents(events);
    }
  }, []);
  const startDate = new Date(rangeStart.getTime());
  startDate.setUTCDate(1);
  startDate.setUTCHours(0, 0, 0, 0);
  startDate.setUTCMonth(startDate.getUTCMonth() - 3);

  const endDate = new Date(rangeEnd.getTime());
  endDate.setUTCHours(23, 59, 59, 0);
  endDate.setUTCMonth(endDate.getUTCMonth() + 3);

  const { data: calendar, refetch: refetchCalendar } = useAdminCalendarGet({
    organizationId: organizationId!,
    startDate: encodeURI(startDate.toISOString()),
    endDate: encodeURI(endDate.toISOString())
  });
  useEffect(() => {
    if (calendar?.data) {
      const events: Partial<EventObject>[] = [];
      calendar.data.forEach((event) => {
        event.recurrences!.forEach((recurrence) => {
          let rule = "Does not repeat";
          if (event.repeat) {
            rule = `Repeats ${getRRuleText(event.repeat)}`;
          }
          const newEvent: Partial<EventObject> = {
            id: event.eventId,
            calendarId: 0,
            title: event.name,
            category: "time",
            start: new Date(recurrence.start!),
            end: new Date(recurrence.end!),
            isReadOnly: true,
            isVisible: true,
            // using raw for timezone
            raw: event.timezone,
            recurrenceRule: rule,
            // using isFocused for livestream flag
            isFocused: event.streamId ? true : false,
            //using dueDateClass for recurrenceId
            dueDateClass: recurrence.recurrenceId
          };
          events.push(newEvent);
        });
      });
      setInitialEvents(events);
    }
  }, [calendar?.data]);

  const eventTemplate = (schedule) => {
    const startDate = new Date(schedule.start);
    const endDate = new Date(schedule.end);
    const isFirstHalfOfWeek = [6, 0, 1, 2].includes(startDate.getDay());
    return `
    <div
      style="overflow:hidden;text-overflow:ellipsis"
      data-tooltip-id="tooltip"
      data-title-attr="${schedule.title}"
      data-startDate-attr="${startDate}"
      data-endDate-attr="${endDate}"
      data-timeZone-attr="${schedule.raw}"
      data-recurrence-attr="${schedule.recurrenceRule}"
      data-day-attr=${startDate.getDay().toString()}
      data-tooltip-place=${
        selectedView !== "day" ? (isFirstHalfOfWeek ? "right" : "left") : "top"
      }
      data-isLive-attr=${schedule.isFocused} 
    >
      ${schedule.title}
    </div>
  `;
  };
  const template = (currentView) => ({
    time(schedule) {
      return eventTemplate(schedule);
    },
    weekDayName(model) {
      const isToday =
        model.dateInstance.d.d.getDate() == today.getDate() &&
        model.dateInstance.d.d.getMonth() == today.getMonth() &&
        model.dateInstance.d.d.getFullYear() == today.getFullYear();

      // Use the currentView variable to check the view
      if (currentView === "day") {
        return `
          <div 
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              height: 100%;
              width: 100%;
              cursor: pointer;
              padding-left: 16px; /* Add padding for spacing */
            "
          >
            ${
              isToday
                ? `
                  <div 
                    style="
                      height: auto;
                      text-align: left;
                      font-size: 14px;
                      background-color: #2B337A;
                      cursor: pointer;
                      padding: 3px 8px;
                      color: #fff;
                      border-radius: 99px;
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                    "
                  >
                    <span style="font-size: 14px;">${model.dayName}</span>
                    <span style="font-size: 14px; margin-left: 4px;">${model.date}</span>
                  </div>
                `
                : `
                  <div 
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                    "
                  >
                    <span style="font-size: 14px;">${model.dayName}</span>
                    <span style="font-size: 14px; margin-left: 4px;">${model.date}</span>
                  </div>
                `
            }
          </div>`;
      }

      // Default behavior for other views
      return `
        <div 
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            cursor: pointer;
          "
        >
          ${
            isToday
              ? `
                <div 
                  style="
                    height: 40px;
                    width: auto;
                    text-align: center;
                    font-size: 14px;
                    background-color: #2B337A;
                    cursor: pointer;
                    padding: 3px 8px;
                    color: #fff;
                    border-radius: 99px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <span style="font-size: 14px;">${model.dayName}</span>
                  <span style="font-size: 14px; margin-left: 4px;">${model.date}</span>
                </div>
              `
              : `
                <div 
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <span style="font-size: 14px;">${model.dayName}</span>
                  <span style="font-size: 14px; margin-left: 4px;">${model.date}</span>
                </div>
              `
          }
        </div>`;
    },
    monthDayName(model) {
      return `<div><span style="font-size: 14px;">${model.label}</span></div>`;
    },
    monthGridHeaderExceed(hiddenEvents) {
      if (hiddenEvents === 1)
        return `<div class="more">${hiddenEvents} more event</div>`;
      if (hiddenEvents > 1)
        return `<div class="more">${hiddenEvents} more events</div>`;
    },
    timegridDisplayPrimaryTime({ time }) {
      let hours = time.getHours();
      const minutes = time.getMinutes().toString().padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      return `${hours}:${minutes} ${ampm}`;
    },
    timegridNowIndicatorLabel({ time }) {
      let hours = time.getHours();
      const minutes = time.getMinutes().toString().padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      return `${hours}:${minutes} ${ampm}`;
    }
  });
  const updateRenderRangeText = useCallback(() => {
    const calInstance = getCalInstance();
    if (!calInstance) {
      setSelectedDateRangeText("");
    }

    const viewName = calInstance.getViewName();
    const calDate = selectedDate;
    const rangeStart = calInstance.getDateRangeStart();
    const rangeEnd = calInstance.getDateRangeEnd();
    setRangeStart(calInstance.getDateRangeStart());
    setRangeEnd(calInstance.getDateRangeEnd());
    let year = calDate.getFullYear();
    let date = calDate.getDate();
    let dateRangeText: string;

    switch (viewName) {
      case "month": {
        dateRangeText = `${new Intl.DateTimeFormat("en-US", {
          month: "short"
        }).format(calDate)} ${year}`;
        break;
      }
      case "week": {
        year = rangeStart.getFullYear();
        date = rangeStart.getDate();
        const endDate = rangeEnd.getDate();

        const start = `${new Intl.DateTimeFormat("en-US", {
          month: "short"
        }).format(rangeStart)} ${date < 10 ? "0" : ""}${date}, ${year}`;
        const end = `${new Intl.DateTimeFormat("en-US", {
          month: "short"
        }).format(rangeEnd)} ${endDate < 10 ? "0" : ""}${endDate}, ${year}`;
        dateRangeText = `${start} - ${end}`;
        break;
      }
      default:
        dateRangeText = `${new Intl.DateTimeFormat("en-US", {
          month: "short"
        }).format(calDate)} ${date}, ${year}`;
    }

    setSelectedDateRangeText(dateRangeText);
  }, [getCalInstance, selectedDate]);

  const { data: settingTimeInterval } = useAdminSettingsGet({
    organizationId: organizationId!,
    parentId: "general"
  });

  const convertView = (period) => {
    switch (period) {
      case "DAILY":
        return "day";
      case "WEEKLY":
        return "week";
      case "MONTHLY":
        return "month";
      default:
        return "month";
    }
  };

  useEffect(() => {
    if (settingTimeInterval?.data) {
      const settingsOrg = settingTimeInterval.data as ModelSetting[];
      const calendarViewSettings = settingsOrg.find(
        (s) => s.settingId === "general.default-calendar-view"
      );
      if (calendarViewSettings) {
        const orgSetting =
          calendarViewSettings.organizationSettings &&
          calendarViewSettings.organizationSettings.length > 0
            ? calendarViewSettings.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )?.value
            : (calendarViewSettings as { value: string }).value ||
              calendarViewSettings.default;
        if (orgSetting) {
          if (firstCalendarViewRender.current) {
            setSelectedView(convertView(orgSetting)!);
            firstCalendarViewRender.current = false;
            setRefreshKey((prev) => prev + 1);
          } else {
            setSelectedView(selectedView);
          }
        }
      }
    }
  }, [settingTimeInterval]);

  useEffect(() => {
    updateRenderRangeText();
  }, [selectedView, updateRenderRangeText]);

  const onAfterRenderEvent: ExternalEventTypes["afterRenderEvent"] = (res) => {
    const eleme = document.querySelector(`[data-event-id="${res.id}"]`);
    if (eleme) {
      const startDate = new Date(res.start);
      const isFirstHalfOfWeek = [6, 0, 1, 2].includes(startDate.getDay());
      eleme.setAttribute("data-tooltip-id", "tooltip");
      eleme.setAttribute(
        "data-tooltip-place",
        selectedView !== "day" ? (isFirstHalfOfWeek ? "right" : "left") : "top"
      );
      eleme.setAttribute("data-title-attr", res.title);
      eleme.setAttribute("data-startDate-attr", res.start);
      eleme.setAttribute("data-day-attr", startDate.getDay().toString());
      eleme.setAttribute("data-endDate-attr", res.end);
      eleme.setAttribute("data-timeZone-attr", res.raw);
      eleme.setAttribute("data-recurrence-attr", res.recurrenceRule);
      eleme.setAttribute("data-isLive-attr", res.isFocused);
    }
  };

  const onClickDayName: ExternalEventTypes["clickDayName"] = (res) => {
    if (selectedView === "month") return;
    setEventDialogOpen(false);
    getCalInstance().setDate(new Date(res.date));
    setSelectedDate(new Date(res.date));
    updateRenderRangeText();
    setSelectedView("day");
  };

  const onClickNavi = (actionName) => {
    getCalInstance()[actionName]();
    setSelectedDate(new Date(getCalInstance().getDate()));
    updateRenderRangeText();
  };

  const onClickEvent: ExternalEventTypes["clickEvent"] = (res) => {
    setEventDialogDetails({
      action: "view",
      id: res.event.id,
      //using dueDateClass for recurrenceId
      recurrenceId: res.event.dueDateClass,
      view: selectedView,
      title: "View Event",
      // using isFocused for livestream flag
      isLiveStream: res.event.isFocused,
      startDate: res.event.start,
      endDate: res.event.end
    });
    setEventViewDialogOpen(true);
  };

  const convertInterval = (period) => {
    switch (period) {
      case "15MIN":
        return 15;
      case "30MIN":
        return 30;
      case "1HR":
        return 60;
      default:
        return 15;
    }
  };

  const onClickSchedule: ExternalEventTypes["selectDateTime"] = (res) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const interval = convertInterval(
      settingTimeInterval?.data[1].value as string
    );

    const resStart = new Date(res.start);
    const resStartDate = new Date(
      resStart.getFullYear(),
      resStart.getMonth(),
      resStart.getDate()
    );

    if (resStartDate >= today) {
      if (permissions.create) {
        setEventDialogDetails({
          action: "create",
          id: "",
          recurrenceId: "",
          view: selectedView,
          title: "Add Event",
          isLiveStream: false,
          startDate:
            selectedView == "month"
              ? new Date(
                  new Date(res.start.setHours(now.getHours())).setMinutes(
                    now.getMinutes() + interval
                  )
                )
              : res.start,
          endDate:
            selectedView == "month"
              ? new Date(
                  new Date(res.end.setHours(now.getHours())).setMinutes(
                    now.getMinutes() + interval
                  )
                )
              : res.end
        });
        setEventDialogOpen(true);
      }
    }
  };

  const handleMonthDateClick = (e) => {
    const targetElement = e.target.parentNode;
    const calInstance = getCalInstance();
    const computedStyle = window.getComputedStyle(targetElement);
    const date = targetElement.innerText;
    if (computedStyle.color === "rgb(187, 187, 187)") {
      if (date < 18) {
        const newDate = new Date(calInstance.getDate());
        newDate.setMonth(newDate.getMonth() + 1);
        newDate.setDate(date);
        calInstance.setDate(newDate);
        updateRenderRangeText();
        setSelectedDate(newDate);
        setSelectedView("day");
      }
      if (date > 18) {
        const newDate = new Date(calInstance.getDate());
        newDate.setMonth(newDate.getMonth() - 1);
        newDate.setDate(date);
        calInstance.setDate(newDate);
        updateRenderRangeText();
        setSelectedDate(newDate);
        setSelectedView("day");
      }
    }
    if (
      computedStyle.color === "rgb(51, 51, 51)" ||
      computedStyle.color === "rgb(0, 150, 136)"
    ) {
      const newDate = new Date(calInstance.getDate());
      newDate.setDate(date);
      calInstance.setDate(newDate);
      updateRenderRangeText();
      setSelectedDate(newDate);
      setSelectedView("day");
    }
  };

  const element = document.querySelectorAll(".toastui-calendar-grid-cell-date");
  useEffect(() => {
    if (element) {
      element.forEach((element) => {
        element.addEventListener("click", handleMonthDateClick);
      });

      return () => {
        element.forEach((element) => {
          element.removeEventListener("click", handleMonthDateClick);
        });
      };
    }
  }, [element]);

  const eventDialogClose = () => {
    setEventDialogOpen(false);
    setEventViewDialogOpen(false);
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("general.calendar", "ADD");
      const edit = await checkPermission("general.calendar", "EDIT");
      const del = await checkPermission("general.calendar", "DELETE");
      const importPermission = await checkPermission(
        "general.calendar-import",
        "ON"
      );
      setPermissions({
        create,
        edit,
        delete: del,
        import: importPermission
      });
    };
    fetchPermissions();
  }, [organizationId]);

  const checkTodayButtonDisabled = () => {
    const today = new Date();
    if (!getCalInstance()) return false;
    const startDate = new Date(getCalInstance().getDateRangeStart());
    const endDate = new Date(getCalInstance().getDateRangeEnd());
    if (today >= startDate && today <= endDate) {
      return true;
    }
  };

  useEffect(() => {
    getCalInstance()?.setDate(selectedDate);
  }, [refreshKey]);

  const convertTo12HourFormat = (timeRange) => {
    const [start, end] = timeRange.split(" - ");

    const convertTime = (timeStr) => {
      const hasAM = /AM/i.test(timeStr);
      const hasPM = /PM/i.test(timeStr);

      const [hours, minutes] = timeStr
        .replace(/\s?(AM|PM)/gi, "")
        .split(":")
        .map(Number);

      let period;
      if (hasPM) {
        period = "PM";
      } else if (hasAM) {
        period = "AM";
      } else {
        period = hours >= 12 ? "PM" : "AM";
      }

      const adjustedHours = hours % 12 || 12;

      return `${adjustedHours}:${String(minutes).padStart(2, "0")} ${period}`;
    };
    return `${convertTime(start)} - ${convertTime(end)}`;
  };

  const handleContentChange = (mutationsList) => {
    mutationsList.forEach((mutation) => {
      if (mutation.type === "childList" || mutation.type === "characterData") {
        const element = mutation.target;
        const originalValue = element.textContent;
        const convertedValue = convertTo12HourFormat(originalValue);
        if (originalValue !== convertedValue) {
          element.textContent = convertedValue;
        }
      }
    });
  };

  const useObserver = () => {
    useEffect(() => {
      const parentElement = document.body;

      const handleNewSelectionElement = (mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (mutation.type === "childList") {
            mutation.addedNodes.forEach((node) => {
              if (
                node.nodeType === 1 &&
                node.classList.contains("toastui-calendar-grid-selection")
              ) {
                const observer = new MutationObserver(handleContentChange);
                observer.observe(node, {
                  childList: true,
                  characterData: true,
                  subtree: true
                });
              }
            });
          }
        });
      };

      const parentObserver = new MutationObserver(handleNewSelectionElement);
      parentObserver.observe(parentElement, {
        childList: true,
        subtree: true
      });

      return () => {
        parentObserver.disconnect();
      };
    }, [refreshKey]);
  };

  useObserver();

  return (
    <>
      <Toolbar
        sx={{
          width: "100%",
          justifyContent: "space-between",
          marginTop: "32px"
        }}
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <StyledButtonGroup
            color="primary"
            value={selectedView}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <StyledButton value="day" data-testid="dayBtn">
              Day
            </StyledButton>
            <StyledButton value="week" data-testid="weekBtn">
              Week
            </StyledButton>
            <StyledButton value="month" data-testid="monthBtn">
              Month
            </StyledButton>
          </StyledButtonGroup>
          <Box sx={{ marginLeft: "16px" }}>
            <StyledMuiButton
              data-testid="todayBtn"
              disabled={checkTodayButtonDisabled()}
              variant="outlined"
              onClick={() => {
                getCalInstance().today();
                updateRenderRangeText();
                setSelectedDate(new Date());
              }}
            >
              Today
            </StyledMuiButton>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
        >
          <SearchInput
            data-testid="searchBtn"
            placeholder="Search"
            required={false}
            InputProps={{
              onClick: () => setSearchOpen(true),
              sx: {
                borderRadius: "99px",
                height: "40px",
                maxWidth: 230
              }
            }}
          />
          {permissions.import && (
            <StyledMuiButton
              onClick={() => navigate("/calendar/import-events")}
              variant="outlined"
              data-testId="importBtn"
              startIcon={<DownloadIcon />}
            >
              Import
            </StyledMuiButton>
          )}
          {permissions.create && (
            <div style={{ width: "100px" }}>
              <Button
                onClick={() => {
                  setEventDialogDetails({
                    action: "create",
                    id: "",
                    recurrenceId: "",
                    view: selectedView,
                    title: "Add Event",
                    isLiveStream: false,

                    startDate: new Date(),
                    endDate: new Date()
                  });
                  setEventDialogOpen(true);
                }}
                variant="admin-primary"
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            </div>
          )}
        </Box>
      </Toolbar>
      <div style={{ width: "100%" }} data-testId="eventCreateUpdateDialog">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "24px",
            marginTop: "16px"
          }}
        >
          <div
            style={{ display: "flex", cursor: "pointer", userSelect: "none" }}
            data-testid="previous-period-button"
            onClick={() => onClickNavi("prev")}
          >
            <ChevronLeftIcon
              type="button"
              style={{ marginLeft: "32px", color: "#007AFF", fontSize: "38px" }}
            />
            <span
              style={{ fontSize: "24px", fontWeight: 400, color: "#007AFF" }}
            >
              Previous{" "}
              {selectedView.charAt(0).toUpperCase() + selectedView.slice(1)}
            </span>
          </div>
          <div>
            <div
              style={{ fontSize: "24px", fontWeight: 500, lineHeight: "30px" }}
            >
              {selectedDateRangeText}
            </div>
          </div>
          <div
            style={{ display: "flex", cursor: "pointer", userSelect: "none" }}
            data-testid="next-period-button"
            onClick={() => onClickNavi("next")}
          >
            <span
              style={{ fontSize: "24px", fontWeight: 400, color: "#007AFF" }}
            >
              Next{" "}
              {selectedView.charAt(0).toUpperCase() + selectedView.slice(1)}
            </span>
            <ChevronRightIcon
              type="button"
              style={{
                marginRight: "32px",
                color: "#007AFF",
                fontSize: "38px"
              }}
            />
          </div>
        </div>
        {status?.data &&
          (status?.data.status == "PROCESSING" ||
            status?.data.status == "PENDING") && (
            <CustomProgressBar
              title={`For ${status.data.teams?.map((team) => team.name).join(", ") || "For Teams"} • ${status.data.sport?.name || "Unknown Sport"} • ${status.data.season?.name || "Unknown Season"} • ${status.data.fileName || "Unknown File"}`}
              percentageCompleted={
                (status.data.progress! * status.data.recordCount!) / 100
              }
              recordsProcessed={status.data.progress!}
              totalRecords={status.data.recordCount!}
              elapsedTime={formatElapsedTime(elapsedTime)}
            />
          )}
        <Calendar
          key={refreshKey}
          usageStatistics={false}
          height="900px"
          calendars={initialCalendars}
          template={template(selectedView)}
          events={initialEvents}
          useDetailPopup={true}
          useFormPopup={true}
          useMoreEventsPopup={false}
          view={selectedView}
          week={{
            eventView: ["time"],
            taskView: false
          }}
          month={{ visibleEventCount: 3 }}
          theme={theme}
          ref={calendarRef}
          onAfterRenderEvent={onAfterRenderEvent}
          onClickDayName={onClickDayName}
          onClickEvent={onClickEvent}
          onSelectDateTime={onClickSchedule}
          onClickMoreEventsBtn={(model) => {
            getCalInstance()?.setDate(new Date(model.date));
            setSelectedDate(new Date(model.date));
            setSelectedView("day");
            setRefreshKey((prev) => prev + 1);
            updateRenderRangeText();
          }}
        />
      </div>
      <ReactTooltip
        opacity={1}
        border="2px solid #1ABC9C"
        id="tooltip"
        style={{
          backgroundColor: "#fff",
          color: "#222",
          width: "270px",
          height: "fit-content",
          borderRadius: "12px"
        }}
        place="top"
        render={({ activeAnchor }) => {
          const dayOptions: { weekday: "short" } = { weekday: "short" };
          const monthOptions: { month: "short" } = { month: "short" };
          const timeOptions: {
            hour: "numeric";
            minute: "numeric";
            hour12: true;
          } = {
            hour: "numeric",
            minute: "numeric",
            hour12: true
          };
          const title = activeAnchor?.getAttribute("data-title-attr");
          const isLiveStream = activeAnchor?.getAttribute("data-islive-attr");
          const startDate = new Date(
            activeAnchor?.getAttribute("data-startDate-attr") as string
          );
          const endDate = new Date(
            activeAnchor?.getAttribute("data-endDate-attr") as string
          );
          const timeZone = activeAnchor?.getAttribute("data-timeZone-attr");
          const recurrence =
            activeAnchor
              ?.getAttribute("data-recurrence-attr")
              ?.replace(/\s+for\s+\d+\s+times/, "") || "";
          return (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="tooltip-title">{title}</div>
                {isLiveStream == "true" && (
                  <img style={{ color: "red" }} src={liveStreamIcon} />
                )}
              </div>
              <div
                style={{
                  color: "#64748B",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  marginTop: "3px"
                }}
              >
                <div>
                  {startDate.toLocaleDateString("en-US", dayOptions)},{" "}
                  {startDate.toLocaleDateString("en-US", monthOptions)}{" "}
                  {startDate.getDate()} &#x25cf;{" "}
                  {startDate.toLocaleTimeString("en-US", timeOptions)} -{" "}
                  {endDate.toLocaleTimeString("en-US", timeOptions)}
                </div>
                <div>{timeZone}</div>
                <div>{recurrence}</div>
              </div>
            </div>
          );
        }}
      />
      {searchOpen && (
        <CalendarSearch
          isSearchOpen={searchOpen}
          toggleSearchOpen={setSearchOpen}
          reLoadCalendar={refetchCalendar}
        />
      )}
      {eventDialogOpen && (
        <CalendarEventCreateUpdate
          details={eventDialogDetails}
          button={`Save`}
          onClose={eventDialogClose}
          reLoadCalendar={refetchCalendar}
        />
      )}
      {eventViewDialogOpen && (
        <CalendarEventView
          details={eventDialogDetails}
          button={`Save`}
          onClose={eventDialogClose}
          hasPermissionToEdit={permissions.edit}
          hasPermissionToDelete={permissions.delete}
          reLoadCalendar={refetchCalendar}
          openEdit={() => {
            setEventDialogDetails({
              action: "edit",
              id: eventDialogDetails.id,
              recurrenceId: eventDialogDetails.recurrenceId,
              view: selectedView,
              title: "Edit Event",
              isLiveStream: eventDialogDetails.isLiveStream,
              startDate: eventDialogDetails.startDate,
              endDate: eventDialogDetails.endDate
            });
            eventDialogClose();
            setEventDialogOpen(true);
          }}
        />
      )}
    </>
  );
};
