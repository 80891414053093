import {
  Cell,
  Compatible,
  getCellProperty,
  TextCellTemplate,
  Uncertain
} from "@silevis/reactgrid";
import * as React from "react";

export interface WrappedTextCell extends Cell {
  type: "text"; // Keep it as "text" so ReactGrid treats it like a normal TextCell
  text: string;
}

export class WrappedTextTemplate extends TextCellTemplate {
  getCompatibleCell(
    uncertainCell: Uncertain<WrappedTextCell>
  ): Compatible<WrappedTextCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    return { ...uncertainCell, text, value: text };
  }

  render(
    cell: Compatible<WrappedTextCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<WrappedTextCell>, commit: boolean) => void
  ): React.ReactNode {
    if (isInEditMode) {
      return (
        <input
          type="text"
          value={cell.text}
          onChange={(e) => {
            if (e.target.value.length <= 75) {
              onCellChanged(
                { ...cell, text: e.target.value, value: e.target.value },
                false
              );
            }
          }}
          onBlur={() => onCellChanged(cell, true)}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            outline: "none"
          }}
          autoFocus
        />
      );
    }

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "flex",
          alignItems: "center",
          cursor: "text"
        }}
      >
        {cell.text}
      </div>
    );
  }
}
