import { Autocomplete, LoadScript } from "@react-google-maps/api";
import {
  Cell,
  CellTemplate,
  Compatible,
  getCellProperty,
  Uncertain,
  UncertainCompatible
} from "@silevis/reactgrid";
import * as React from "react";

const libraries = ["places"] as "places"[];

export interface LocationCell extends Cell {
  type: "location";
  text: string;
  width: number;
  height: number;
  rowId: number;
}

export class LocationTemplate implements CellTemplate<LocationCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<LocationCell>
  ): Compatible<LocationCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    const width = getCellProperty(uncertainCell, "width", "number");
    const height = getCellProperty(uncertainCell, "height", "number");
    const rowId = getCellProperty(uncertainCell, "rowId", "number");

    return { ...uncertainCell, text, width, height, rowId, value: text };
  }

  update(
    cell: Compatible<LocationCell>,
    cellToMerge: UncertainCompatible<LocationCell>
  ): Compatible<LocationCell> {
    return this.getCompatibleCell({ ...cell, ...cellToMerge });
  }

  render(
    cell: Compatible<LocationCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<LocationCell>, commit: boolean) => void
  ): React.ReactNode {
    isInEditMode = true;
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const autocompleteRef =
      React.useRef<google.maps.places.Autocomplete | null>(null);

    React.useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, []);

    const onPlaceChanged = () => {
      if (autocompleteRef.current) {
        const place = autocompleteRef.current.getPlace();
        if (place?.formatted_address) {
          const placeId = place.place_id || "";

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (typeof (window as any).onPlaceSelected === "function") {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).onPlaceSelected(cell.rowId, placeId);
          }

          onCellChanged(
            {
              ...cell,
              text: place.formatted_address
            },
            true
          );
        }
      }
    };

    return (
      <LoadScript
        googleMapsApiKey={import.meta.env.VITE_APP_MAP_API_KEY as string}
        libraries={libraries}
      >
        <Autocomplete
          onLoad={(autocomplete) => {
            autocompleteRef.current = autocomplete;
          }}
          onPlaceChanged={onPlaceChanged}
        >
          <input
            ref={inputRef}
            type="text"
            defaultValue={cell.text}
            onChange={(event) => {
              onCellChanged({ ...cell, text: event.target.value }, true);
            }}
            style={{
              width: `${cell.width}px`,
              height: `${cell.height}px`,
              fontSize: "14px",
              padding: "4px",
              border: "none",
              outline: "none",
              background: "white",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          />
        </Autocomplete>
      </LoadScript>
    );
  }
}
