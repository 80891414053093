import { Button } from "@components/Button";
import { Loader } from "@components/crud/Loader";
import { FormInput } from "@components/FormInput";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import {
  useAdminCrmCustomerSurveyStatusSurveyIdGet,
  useAdminCrmCustomerSurveySurveyIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ScoreScale } from "./components/ScoreScale";
import { Onboarding } from "./Onboarding";

export const Feedback = () => {
  const { control, handleSubmit, watch } = useForm({ mode: "onTouched" });

  const { surveyId } = useParams<{ surveyId: string }>();
  const [score, setScore] = useState<number | undefined>();
  const [isNps, setIsNps] = useState<boolean | undefined>(false);

  const handleScore = (value) => setScore(value);

  const scoreDescription = watch("description", "");

  const { data: surveyDetails, isLoading: isLoadingSurveyData } =
    useAdminCrmCustomerSurveyStatusSurveyIdGet(surveyId!);
  const { mutate: save, isLoading: isSaving } =
    useAdminCrmCustomerSurveySurveyIdPut();

  const onSave = () => {
    const data = {
      score: score,
      scoreDescription: scoreDescription
    };
    save(
      {
        surveyId: surveyId!,
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Succesfully Sent Feedback!", {
            variant: "success"
          });
        },
        onError: () => {
          enqueueSnackbar("Failed to Send Feedback!", {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    if (surveyDetails && surveyDetails.data.type === "NPS") {
      setIsNps(true);
    }
  }, [surveyDetails]);

  return (
    <Loader isLoading={isLoadingSurveyData}>
      {isNps ? (
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Typography
              sx={{ fontWeight: "300", fontSize: "26px", color: "#000000" }}
            >
              Your Feedback Matters
            </Typography>
            <Typography
              sx={{ fontWeight: "400", fontSize: "14px", color: "#666666" }}
            >
              We'd love to hear your thoughts! Please rate your experience with
              SportsGravy below.
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography
              sx={{ fontWeight: "600", fontSize: "20px", color: "#000000" }}
            >
              How likely are you to recommend us to your friend or colleague?
            </Typography>
          </Grid>
          <Grid xs={12}>
            <ScoreScale onClick={handleScore} />
          </Grid>
          <Grid xs={12}>
            <Box
              sx={{
                width: "100%",
                height: "2px",
                backgroundColor: "lightgrey"
              }}
            />
          </Grid>
          <Grid xs={12}>
            <FormInput
              label="What is the primary reason for your score?"
              name="description"
              control={control}
              multiline={true}
              rows={4}
              type="text"
            />
          </Grid>
          <Grid xs={12}>
            <Button
              variant="admin-primary"
              onClick={handleSubmit(onSave)}
              disabled={!score}
              isLoading={isSaving}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Onboarding />
      )}
    </Loader>
  );
};
