import { Button } from "@components/Button";
import { Loader } from "@components/crud/Loader";
import Grid from "@mui/system/Unstable_Grid";
import {
  useAdminCrmCustomerSurveyStatusSurveyIdGet,
  useAdminCrmCustomerSurveySurveyIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { IntervalFeedback } from "./components/IntervalFeedback";
import { FeedbackResponse } from "./FeedbackResponse";

export const Onboarding = () => {
  const { control, handleSubmit, watch } = useForm({ mode: "onTouched" });

  const { surveyId } = useParams<{ surveyId: string }>();
  const [score, setScore] = useState<number | undefined>();
  const [isIntervalFeedback, setIsIntervalFeedback] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);

  const handleScore = (value) => setScore(value);

  const scoreDescription = watch("description", "");

  const { mutate: save, isLoading: isSaving } =
    useAdminCrmCustomerSurveySurveyIdPut();
  const { data: surveyDetails, isLoading: isLoadingSurveyData } =
    useAdminCrmCustomerSurveyStatusSurveyIdGet(surveyId!);

  const onSave = () => {
    const data = {
      score: score,
      scoreDescription: scoreDescription
    };
    save(
      {
        surveyId: surveyId!,
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Succesfully Sent Feedback!", {
            variant: "success"
          });
          setCompleted(true);
        },
        onError: () => {
          enqueueSnackbar("Failed to Send Feedback!", {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    if (surveyDetails && !surveyDetails.data.isInitialSurvey) {
      setIsIntervalFeedback(true);
    }
  }, [surveyDetails]);

  return (
    <Loader isLoading={isLoadingSurveyData}>
      {completed ? (
        <FeedbackResponse score={score as number} />
      ) : (
        <IntervalFeedback
          handleScore={handleScore}
          isIntervalFeedback={isIntervalFeedback}
          control={control}
        />
      )}
      <Grid xs={12}>
        <Button
          variant="admin-primary"
          onClick={handleSubmit(onSave)}
          disabled={!score}
          isLoading={isSaving}
        >
          Submit
        </Button>
      </Grid>
    </Loader>
  );
};
