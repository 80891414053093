/* eslint-disable @typescript-eslint/no-explicit-any */

import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Footer } from "@components/crud/Footer";
import { uploadMediaUsingUploadParams } from "@services/customNetworkCalls";
import {
  BorderProps,
  Column,
  DropdownCell,
  HeaderCell,
  ReactGrid,
  Row,
  TextCell
} from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import { useAdminCalendarUploadCsvPost } from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import Papa from "papaparse";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BasicTextTemplate } from "../templates/BasicTextTemplate";
import { DateTimeCell, DateTimeTemplate } from "../templates/DateTimeTemplate";
import { LocationCell, LocationTemplate } from "../templates/LocationTemplate";
import { WrappedTextTemplate } from "../templates/WrappedTextTemplate";

interface Event {
  id: number;
  title?: string;
  type?: string;
  team?: string;
  isHome?: boolean;
  isPrivate?: boolean;
  start?: string;
  end?: string;
  location?: string;
  liveStream?: string;
  opponent?: string;
  isPublic?: string;
  shareWith?: string;
  description?: string;
  isTypeOpen?: boolean;
  isTeamOpen?: boolean;
  isHomeOpen?: boolean;
  isPrivateOpen?: boolean;
  isLiveStreamOpen?: boolean;
  isPublicOpen?: boolean;
  isShareWithOpen?: boolean;
}
enum EventType {
  GENERAL_EVENT = "General Event",
  GAME_MATCH = "Game / Match",
  SCRIMMAGE = "Scrimmage",
  PRACTICE = "Practice",
  ATHLETIC_EVENT = "Athletic Event"
}

export const EventMapperGrid = ({
  file,
  fileType,
  usedFields,
  teamsOptions,
  uploadId
}: {
  file: File;
  fileType: string;
  usedFields: string[];
  teamsOptions: { label: string; value: string }[];
  uploadId: string;
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const gridRef = useRef<HTMLDivElement>(null);
  const [isHoveringGrid, setIsHoveringGrid] = useState(false);
  const { getValues } = useFormContext();
  const [disabledCells, setDisabledCells] = useState<
    { rowId: number; key: string }[]
  >([]);
  const [placeIdMap, setPlaceIdMap] = useState<Record<number, string>>({});
  const handlePlaceSelected = (rowId: number, placeId: string) => {
    setPlaceIdMap((prev) => ({
      ...prev,
      [rowId]: placeId
    }));
  };

  useEffect(() => {
    (window as any).onPlaceSelected = handlePlaceSelected;
    return () => {
      (window as any).onPlaceSelected = null;
    };
  }, []);

  const { mutate, isLoading: isSaving } = useAdminCalendarUploadCsvPost();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const mappedHeaders = [
    { key: "title", value: "Title" },
    { key: "type", value: "Event Type" },
    { key: "team", value: "Team" },
    { key: "isHome", value: "Is Home Team" },
    { key: "isPrivate", value: "Is Private Event" },
    { key: "start", value: "Start Date and Time" },
    { key: "end", value: "End Date and Time" },
    { key: "location", value: "Location" },
    { key: "liveStream", value: "Live Stream" },
    { key: "opponent", value: "Opposing Teams/Organizations" },
    { key: "isPublic", value: "Make Available to public" },
    { key: "shareWith", value: "Share with" },
    { key: "description", value: "Description" }
  ];

  const tooltipTexts: Record<string, string> = {
    Title: "Please enter a title for the event.",
    "Event Type": "This field specifies the general category of the event",
    Team: "Please select the team associated with the event.",
    "Is Home Team":
      "This field is required for Game/Match, Scrimmage, and Athletic Event types",
    "Is Private Event":
      "Please enter “Yes” or “No” box to keep this event private. It will not be visible to the public and will only appear on the calendars of shared guests.",
    "Start Date and Time":
      "Please enter the exact start date and time of the event.",
    "End Date and Time":
      "Please enter the exact end date and time of the event.",
    Location:
      "Please provide the event's correct location from the Google Maps search results. Both the name of the athletic facility or the detailed address work.",
    "Live Stream":
      "Please select “Yes” or “No” for whether or not your organization plans to open viewership of this event's live stream to all SportsGravy users.",
    "Opposing Teams/Organizations":
      "Please enter the opposing team name for this game/match or scrimmage event. If this is an athletic event, please enter the opposing organization names.",
    "Make Available to public":
      "Please select “Yes” or “No” for whether or not your organization plans to open viewership of this event's live stream to all SportsGravy users.",
    "Share with": "Please select “Team” or “Organization”",
    Description:
      "If you want to provide a detailed description of the event, please enter the description in the field below"
  };

  const dropdownOptions: Record<string, { label: string; value: string }[]> = {
    type: [
      { label: "General Event", value: EventType.GENERAL_EVENT },
      { label: "Game / Match", value: EventType.GAME_MATCH },
      { label: "Scrimmage", value: EventType.SCRIMMAGE },
      { label: "Practice", value: EventType.PRACTICE },
      { label: "Athletic Event", value: EventType.ATHLETIC_EVENT }
    ],
    team: teamsOptions,
    isHome: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" }
    ],
    isPrivate: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" }
    ],
    liveStream: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" }
    ],
    isPublic: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" }
    ],
    shareWith: [
      { label: "Selected Team", value: "Selected Team" },
      { label: "Organization", value: "Organization" }
    ]
  };

  const normalizeString = (str: string) =>
    str.replace(/^SG_/, "").replace(/_/g, " ").toLowerCase();

  const usedFieldsNormalized = usedFields.map(normalizeString);

  const createDateFormatter = (timeZone: string) =>
    new Intl.DateTimeFormat("en-US", {
      timeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    });
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateFormatter = createDateFormatter(userTimeZone);

  const validHeaders = mappedHeaders.filter(({ value, key }) => {
    const normalizedValue = normalizeString(value);
    const normalizedKey = normalizeString(key);

    return usedFieldsNormalized.some(
      (usedField) =>
        usedField === normalizedValue ||
        usedField === normalizedKey ||
        usedField.includes(normalizedValue) ||
        normalizedValue.includes(usedField) ||
        usedField.includes(normalizedKey) ||
        normalizedKey.includes(usedField)
    );
  });

  useEffect(() => {
    const updatedDisabledCells: { rowId: number; key: string }[] = [];

    events.forEach((event) => {
      if (event.type === "General Event" || event.type === "Practice") {
        updatedDisabledCells.push({ rowId: event.id, key: "isHome" });
      }
      if (event.type === "General Event") {
        updatedDisabledCells.push({ rowId: event.id, key: "isPrivate" });
        updatedDisabledCells.push({ rowId: event.id, key: "liveStream" });
      }
    });

    setDisabledCells((prevDisabledCells) => {
      if (
        JSON.stringify(prevDisabledCells) !==
        JSON.stringify(updatedDisabledCells)
      ) {
        return updatedDisabledCells;
      }
      return prevDisabledCells;
    });
  }, [events]);

  const getTextHeight = (
    text: string,
    maxWidth: number,
    font: string = "16px Arial"
  ) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) return 24;

    context.font = font;
    const textWidth = context.measureText(text).width;

    if (textWidth <= maxWidth) {
      return 24;
    }

    const lineHeight = 20;
    const lines = Math.ceil(textWidth / maxWidth);

    return lines * lineHeight;
  };

  const getTextWidth = (text: string, font: string = "16px Arial") => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (context) {
      context.font = font;
      const textWidth = context.measureText(text).width;
      const tooltipIconWidth = 20;

      return textWidth + tooltipIconWidth + 12;
    }

    return 150;
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? dateString : date.toLocaleString();
  };

  useEffect(() => {
    if (file && fileType) {
      if (fileType === "csv") {
        parseCSVFile(file);
      } else if (fileType === "ics") {
        parseICSFile(file);
      }
    }
  }, [file, fileType]);

  const parseCSVFile = (file: File) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
      complete: (results) => {
        const parsedData = results.data as any[];

        const filteredData = parsedData.filter((row) =>
          Object.values(row).some((val) => val !== null && val !== "")
        );

        const fieldMap = getValues().fieldMap.event;

        const fieldHeaderMap: Record<string, string> = Object.entries(
          fieldMap
        ).reduce(
          (acc, [key, value]) => {
            if (value.header) acc[value.header] = key;
            return acc;
          },
          {} as Record<string, string>
        );

        const formattedEvents = filteredData.map((row, index) => {
          const event: Partial<Event> = { id: index + 1 };

          validHeaders.forEach(({ key, value }) => {
            const actualKey = fieldHeaderMap[value] || key;
            event[actualKey as keyof Event] = row[value] || "";
          });

          event.isHome = event.isHome || false;
          event.isPrivate = event.isPrivate || false;

          return event as Event;
        });

        setEvents(formattedEvents);
      }
    });
  };

  const parseICSFile = (file: File) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
      complete: (results) => {
        const parsedData = results.data as any[];
        const formattedEvents = parsedData.map((row, index) => ({
          id: index + 1,
          type: row["Event Type"] || "",
          team: row["Team"] || "",
          isHome: row["Is Home"] === "Yes" ? true : false,
          isPrivate: row["Is Private"] === "Yes" ? true : false,
          title: row["Title"] || "",
          start: row["Start Date and Time"] || "",
          end: row["End Date and Time"] || "",
          location: row["Event Location"] || "",
          liveStream: row["Live Stream"] || "",
          opponent: row["Opposing Teams/Organizations"] || "",
          isPublic: row["Make available to public"] || "",
          shareWith: row["Share with"] || "",
          description: row["Event Description"] || ""
        }));
        setEvents(formattedEvents);
      }
    });
  };

  const getColumns = (
    events: Event[]
  ): { columns: Column[]; maxWidths: Record<string, number> } => {
    const maxWidths: Record<string, number> = {};

    mappedHeaders.forEach(({ key, value }) => {
      let maxWidth = key === "title" ? 233 : getTextWidth(value);

      events.forEach((event) => {
        let text = String(event[key as keyof Event] || "").trim();

        if (text === "") {
          maxWidth = Math.max(maxWidth, 150);
          return;
        }

        if (key === "start" || key === "end") {
          text = formatDate(text);
        }

        if (key in dropdownOptions) {
          const selectedValue = event[key as keyof Event] || "";
          if (selectedValue) {
            text = selectedValue as string;
          }
        }

        const textWidth = getTextWidth(text) + 20;
        maxWidth = key === "title" ? 233 : Math.max(maxWidth, textWidth);
      });

      maxWidths[key] = maxWidth;
    });

    const columns: Column[] = [
      { columnId: "index", width: 50 },
      ...mappedHeaders.map((header) => ({
        columnId: header.key,
        width: maxWidths[header.key] || 150
      }))
    ];

    return { columns, maxWidths };
  };

  const getBorderStyles = (border?: BorderProps) => ({
    color: border?.color || "#B3B3B3",
    style: border?.style || "solid",
    width: border?.width || "2px"
  });

  const getCsvRows = (
    events: Event[],
    fieldMap: any,
    maxWidths: Record<string, number>
  ): Row[] => {
    const defaultWidth = 150;
    const textWrapThreshold = 200;
    const defaultHeight = 24;
    const disabledColor = "#E5E5E5";
    const errorColor = "#FFEEEE";
    const alphabeticalHeaderRow: Row = {
      rowId: "alphabetical-header",
      cells: [
        {
          type: "header",
          text: " ",
          style: {
            border: {
              right: getBorderStyles(),
              bottom: getBorderStyles()
            }
          }
        },
        ...validHeaders.map(({ key }, index) => {
          const letter = String.fromCharCode(65 + index);
          const letterWidth = getTextWidth(letter);
          const columnWidth = maxWidths[key] || 150;
          const padding = Math.max((columnWidth - letterWidth) / 2, 0);

          return {
            type: "header",
            text: letter,
            style: {
              textAlign: "center",
              paddingLeft: `${padding}px`,
              paddingRight: `${padding}px`,
              ...(letter === "A" && { border: { right: getBorderStyles() } })
            }
          } as HeaderCell;
        })
      ]
    };

    const currentHeaderAsBodyRow: Row = {
      rowId: "header-as-body",
      cells: [
        {
          type: "header",
          text: "1",
          style: {
            paddingLeft: "20px",
            border: {
              right: getBorderStyles(),
              bottom: getBorderStyles()
            }
          }
        },
        ...validHeaders.map(({ value }) => {
          const columnWidth = getTextWidth(value);
          const cellHeight = getTextHeight(value, columnWidth);

          return {
            type: "customHeader",
            text: value,
            tooltipText: tooltipTexts[value] || "No tooltip available",
            width: `${columnWidth}px`,
            height: cellHeight,
            style: {
              border: {
                bottom: getBorderStyles(),
                ...(value === "Title" && { right: getBorderStyles() })
              }
            }
          };
        })
      ],
      height: Math.max(
        ...validHeaders.map(({ value }) =>
          getTextHeight(value, getTextWidth(value))
        )
      )
    };

    return [
      alphabeticalHeaderRow,
      currentHeaderAsBodyRow,
      ...events.map<Row>((event, index) => {
        let maxHeight = defaultHeight;

        const eventType = event.type || "";
        const isEventTypeEmpty = eventType === "";

        validHeaders.forEach(({ key }) => {
          const value = event[key as keyof Event] || "";
          const columnWidth = maxWidths[key] || defaultWidth;
          const cellHeight = getTextHeight(value as string, columnWidth);
          maxHeight = Math.max(maxHeight, cellHeight);
        });

        return {
          rowId: event.id,
          height: maxHeight,
          cells: [
            {
              type: "header",
              text: (index + 2).toString(),
              style: {
                paddingLeft: "20px",
                border: {
                  right: getBorderStyles(),
                  bottom: getBorderStyles()
                }
              }
            },
            ...validHeaders.map(({ key }) => {
              const fieldConfig = fieldMap.event[key];
              const isRequired = fieldConfig?.isRequired || false;
              let value = event[key as keyof Event] || "";
              const columnWidth = maxWidths[key] || defaultWidth;

              let highlightStyle = {};
              let isInvalidDropdownValue = false;
              let isDisabled = false;

              if (key === "opponent") {
                isDisabled =
                  disabledCells.some(
                    (cell) =>
                      cell.rowId === event.id && cell.key === "liveStream"
                  ) || event.liveStream !== "Yes";
              }

              if (key === "isPublic") {
                isDisabled =
                  disabledCells.some(
                    (cell) =>
                      cell.rowId === event.id && cell.key === "liveStream"
                  ) || event.liveStream !== "Yes";
              }

              if (
                (isEventTypeEmpty &&
                  ["isHome", "isPrivate", "liveStream"].includes(key)) ||
                (key === "isHome" &&
                  (eventType === EventType.GENERAL_EVENT ||
                    eventType === EventType.PRACTICE)) ||
                (key === "isPrivate" &&
                  eventType === EventType.GENERAL_EVENT) ||
                (key === "liveStream" && eventType === EventType.GENERAL_EVENT)
              ) {
                isDisabled = true;
              }

              if (key in dropdownOptions) {
                const validOptions = dropdownOptions[key].map(
                  (opt) => opt.value
                );
                isInvalidDropdownValue =
                  !!value && !validOptions.includes(value as string);
              }
              if (isDisabled) {
                highlightStyle = {
                  background: disabledColor,
                  color: "#6B7280"
                };
                value = "";
              } else if (isRequired && (!value || isInvalidDropdownValue)) {
                highlightStyle = { background: errorColor };
              }
              let isOpenKey = `is${key.charAt(0).toUpperCase() + key.slice(1)}Open`;
              if (
                key === "isHome" ||
                key === "isPrivate" ||
                key === "isPublic"
              ) {
                isOpenKey = `${key}Open`;
              }

              if (key === "location") {
                return {
                  type: "location",
                  text: "",
                  width: maxWidths[key] || defaultWidth,
                  height: maxHeight,
                  rowId: event.id
                } as LocationCell;
              }
              if (key === "start" || key === "end") {
                const date = new Date(value as string);
                return {
                  type: "datetime",
                  date: isNaN(date.getTime()) ? new Date() : date
                } as DateTimeCell;
              }

              if (key in dropdownOptions) {
                const selectedValue = value ? value : "";
                const textWidth = selectedValue
                  ? getTextWidth(selectedValue as string) + 20
                  : defaultWidth;
                return {
                  type: "dropdown",
                  values: dropdownOptions[key],
                  selectedValue: value != "" && !isDisabled ? value : "No",
                  isOpen: event[isOpenKey] || false,
                  style: highlightStyle,
                  width: `${textWidth}px`,
                  height: maxHeight,
                  nonEditable: isDisabled
                } as DropdownCell;
              }

              if (getTextWidth(value as string) > textWrapThreshold) {
                return {
                  type: "WrappedText",
                  text: value,
                  width: columnWidth,
                  height: maxHeight,
                  nonEditable: isDisabled,
                  style: {
                    ...highlightStyle,
                    border: {
                      ...(key === "title" && { right: getBorderStyles() })
                    }
                  }
                };
              }

              return {
                type: "text",
                text: value,
                style: {
                  ...highlightStyle,
                  border: {
                    ...(key === "title" && { right: getBorderStyles() })
                  }
                },
                height: maxHeight,
                nonEditable: isDisabled
              } as TextCell;
            })
          ]
        };
      })
    ];
  };

  const getIcsRows = (
    events: Event[],
    fieldMap: any,
    maxWidths: Record<string, number>
  ): Row[] => {
    const defaultWidth = 150;
    const textWrapThreshold = 200;
    const defaultHeight = 24;
    const disabledColor = "#E5E5E5";
    const errorColor = "#FFEEEE";
    const alphabeticalHeaderRow: Row = {
      rowId: "alphabetical-header",
      cells: [
        {
          type: "header",
          text: " ",
          style: {
            border: {
              right: getBorderStyles(),
              bottom: getBorderStyles()
            }
          }
        },
        ...mappedHeaders.map(({ key }, index) => {
          const letter = String.fromCharCode(65 + index);
          const letterWidth = getTextWidth(letter);
          const columnWidth = maxWidths[key] || 150;
          const padding = Math.max((columnWidth - letterWidth) / 2, 0);

          return {
            type: "header",
            text: letter,
            style: {
              textAlign: "center",
              paddingLeft: `${padding}px`,
              paddingRight: `${padding}px`,
              ...(letter === "A" && { border: { right: getBorderStyles() } })
            }
          } as HeaderCell;
        })
      ]
    };

    const currentHeaderAsBodyRow: Row = {
      rowId: "header-as-body",
      cells: [
        {
          type: "header",
          text: "1",
          style: {
            paddingLeft: "20px",
            border: {
              right: getBorderStyles(),
              bottom: getBorderStyles()
            }
          }
        },
        ...mappedHeaders.map(({ value }) => {
          const columnWidth = getTextWidth(value);
          const cellHeight = getTextHeight(value, columnWidth);

          return {
            type: "customHeader",
            text: value,
            tooltipText: tooltipTexts[value] || "No tooltip available",
            width: `${columnWidth}px`,
            height: cellHeight,
            style: {
              border: {
                bottom: getBorderStyles(),
                ...(value === "Title" && { right: getBorderStyles() })
              }
            }
          };
        })
      ],
      height: Math.max(
        ...validHeaders.map(({ value }) =>
          getTextHeight(value, getTextWidth(value))
        )
      )
    };

    return [
      alphabeticalHeaderRow,
      currentHeaderAsBodyRow,
      ...events.map<Row>((event, index) => {
        let maxHeight = defaultHeight;
        const eventType = event.type || "";
        const isEventTypeEmpty = eventType === "";

        mappedHeaders.forEach(({ key }) => {
          const value = event[key as keyof Event] || "";
          const columnWidth = maxWidths[key] || defaultWidth;
          const cellHeight = getTextHeight(value as string, columnWidth);
          maxHeight = Math.max(maxHeight, cellHeight);
        });

        return {
          rowId: event.id,
          height: maxHeight,
          cells: [
            {
              type: "header",
              text: (index + 2).toString(),
              style: {
                paddingLeft: "20px",
                border: {
                  right: getBorderStyles(),
                  bottom: getBorderStyles()
                }
              }
            },
            ...mappedHeaders.map(({ key }) => {
              const fieldConfig = fieldMap.event[key];
              const isRequired = fieldConfig?.isRequired || false;
              let value = event[key as keyof Event] || "";
              const columnWidth = maxWidths[key] || defaultWidth;

              let highlightStyle = {};
              let isInvalidDropdownValue = false;
              let isDisabled = false;

              if (key === "opponent") {
                isDisabled =
                  disabledCells.some(
                    (cell) =>
                      cell.rowId === event.id && cell.key === "liveStream"
                  ) || event.liveStream !== "Yes";
              }

              if (key === "isPublic") {
                isDisabled =
                  disabledCells.some(
                    (cell) =>
                      cell.rowId === event.id && cell.key === "liveStream"
                  ) || event.liveStream !== "Yes";
              }

              if (
                (isEventTypeEmpty &&
                  ["isHome", "isPrivate", "liveStream"].includes(key)) ||
                (key === "isHome" &&
                  (eventType === EventType.GENERAL_EVENT ||
                    eventType === EventType.PRACTICE)) ||
                (key === "isPrivate" &&
                  eventType === EventType.GENERAL_EVENT) ||
                (key === "liveStream" && eventType === EventType.GENERAL_EVENT)
              ) {
                isDisabled = true;
              }

              if (key in dropdownOptions) {
                const validOptions = dropdownOptions[key].map(
                  (opt) => opt.value
                );
                isInvalidDropdownValue =
                  !!value && !validOptions.includes(value as string);
              }

              if (isDisabled) {
                highlightStyle = {
                  background: disabledColor,
                  color: "#6B7280"
                };
                value = "";
              } else if (isRequired && (!value || isInvalidDropdownValue)) {
                highlightStyle = { background: errorColor };
              }

              let isOpenKey = `is${key.charAt(0).toUpperCase() + key.slice(1)}Open`;
              if (
                key === "isHome" ||
                key === "isPrivate" ||
                key === "isPublic"
              ) {
                isOpenKey = `${key}Open`;
              }

              if (key === "location") {
                return {
                  type: "location",
                  text: "",
                  width: maxWidths[key] || defaultWidth,
                  height: maxHeight,
                  rowId: event.id
                } as LocationCell;
              }
              if (key === "start" || key === "end") {
                let parsedDate: Date | null = null;
                const dateParts = (value as string).match(
                  /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/
                );
                if (dateParts) {
                  const [, month, day, year, hour, minute, second] =
                    dateParts.map(Number);
                  parsedDate = new Date(
                    year,
                    month - 1,
                    day,
                    hour,
                    minute,
                    second
                  );
                } else {
                  parsedDate = new Date(value as string);
                }
                return {
                  type: "datetime",
                  date: isNaN(parsedDate.getTime()) ? new Date() : parsedDate
                } as DateTimeCell;
              }

              if (key in dropdownOptions) {
                const selectedValue = value ? value : "";
                const textWidth = selectedValue
                  ? getTextWidth(selectedValue as string) + 20
                  : defaultWidth;
                return {
                  type: "dropdown",
                  values: dropdownOptions[key],
                  selectedValue: value != "" && !isDisabled ? value : "No",
                  isOpen: event[isOpenKey] || false,
                  style: highlightStyle,
                  width: `${textWidth}px`,
                  height: maxHeight,
                  nonEditable: isDisabled
                } as DropdownCell;
              }

              if (getTextWidth(value as string) > textWrapThreshold) {
                return {
                  type: "WrappedText",
                  text: value,
                  width: columnWidth,
                  height: maxHeight,
                  nonEditable: isDisabled,
                  style: {
                    ...highlightStyle,
                    border: {
                      ...(key === "title" && { right: getBorderStyles() })
                    }
                  }
                };
              }

              return {
                type: "text",
                text: value,
                style: {
                  ...highlightStyle,
                  border: {
                    ...(key === "title" && { right: getBorderStyles() })
                  }
                },
                height: maxHeight,
                nonEditable: isDisabled
              } as TextCell;
            })
          ]
        };
      })
    ];
  };

  const generateCsvToCSV = () => {
    if (!rows.length) return;

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const csvRows = rows
      .filter(
        (row) =>
          row.rowId !== "alphabetical-header" && row.rowId !== "header-as-body"
      )
      .map((row) =>
        row.cells.slice(1).map((cell, columnIndex) => {
          const key = validHeaders[columnIndex].key;

          if ("selectedValue" in cell) {
            let cellValue = cell.selectedValue || "";

            if (key === "type") {
              if (cellValue === EventType.GENERAL_EVENT) {
                cellValue = "";
              } else {
                const enumKey = Object.keys(EventType).find(
                  (k) => EventType[k as keyof typeof EventType] === cellValue
                );
                cellValue = enumKey || cellValue;
              }
            }

            return cellValue;
          } else if ("date" in cell) {
            if (cell.date) {
              const localDate = new Date(cell.date);
              const utcDate = new Intl.DateTimeFormat("en-US", {
                timeZone: userTimeZone,
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false
              }).format(localDate);

              return new Date(utcDate).toISOString();
            }
            return "";
          } else if ("text" in cell) {
            if (key === "location") {
              return placeIdMap[row.rowId] || cell.text || "";
            }
            return cell.text || "";
          }
          return "";
        })
      );

    const csv = Papa.unparse({
      fields: validHeaders.map(({ value }) => value),
      data: csvRows
    });
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const csvFile = new File([csvBlob], "event_grid_data.csv", {
      type: "text/csv"
    });
    saveHandler(csvFile);
  };

  const generateIcsToCSV = () => {
    if (!rows.length) return;

    const headers = mappedHeaders.map(({ value }) => value);

    const csvRows = rows
      .filter(
        (row) =>
          row.rowId !== "alphabetical-header" && row.rowId !== "header-as-body"
      )
      .map((row) =>
        row.cells.slice(1).map((cell, columnIndex) => {
          const key = mappedHeaders[columnIndex].key;
          if ("selectedValue" in cell) {
            let cellValue = cell.selectedValue || "";

            if (key === "type") {
              if (cellValue === EventType.GENERAL_EVENT) {
                cellValue = "";
              } else {
                const enumKey = Object.keys(EventType).find(
                  (k) => EventType[k as keyof typeof EventType] === cellValue
                );
                cellValue = enumKey || cellValue;
              }
            }

            return cellValue;
          } else if ("date" in cell) {
            if (cell.date) {
              const localDate = new Date(cell.date);
              const utcDate = dateFormatter.format(localDate);

              return new Date(utcDate).toISOString();
            }
            return "";
          } else if ("text" in cell) {
            if (key === "location") {
              return placeIdMap[row.rowId] || cell.text || "";
            }
            return cell.text || "";
          }
          return "";
        })
      );

    const csv = Papa.unparse({
      fields: headers,
      data: csvRows
    });

    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const csvFile = new File([csvBlob], "event_grid_data.csv", {
      type: "text/csv"
    });
    saveHandler(csvFile);
  };

  const saveHandler = (file) => {
    mutate(
      { uploadId: uploadId },
      {
        onSuccess: async (data) => {
          enqueueSnackbar("Events Uploaded successfully!", {
            variant: "success"
          });
          uploadMediaUsingUploadParams(file, data.data?.uploadParams);
          navigate("/calendar");
        },
        onError: () => {
          enqueueSnackbar("Error uploading events", {
            variant: "error"
          });
        }
      }
    );
  };

  const applyChanges = (changes, prevEvents: Event[]): Event[] => {
    changes.forEach((change) => {
      const eventIndex = change.rowId as number;
      const fieldName = change.columnId;
      const dataRow = prevEvents.find((d) => d.id === eventIndex);
      if (!dataRow) return;
      if (change.type === "dropdown") {
        if (dataRow) {
          if (fieldName == "type") {
            dataRow.isTypeOpen = change.newCell.isOpen as never;
          } else if (fieldName == "team") {
            dataRow.isTeamOpen = change.newCell.isOpen as never;
          } else if (fieldName == "isHome") {
            dataRow.isHomeOpen = change.newCell.isOpen as never;
          } else if (fieldName == "isPrivate") {
            dataRow.isPrivateOpen = change.newCell.isOpen as never;
          } else if (fieldName == "isPublic") {
            dataRow.isPublicOpen = change.newCell.isOpen as never;
          } else if (fieldName == "liveStream") {
            dataRow.isLiveStreamOpen = change.newCell.isOpen as never;
          } else if (fieldName == "shareWith") {
            dataRow.isShareWithOpen = change.newCell.isOpen as never;
          }
          if (
            change.newCell.selectedValue &&
            change.newCell.selectedValue !== change.previousCell.selectedValue
          ) {
            prevEvents[eventIndex - 1][fieldName] =
              change.newCell.selectedValue;
          }
        }
      }
      if (change.type === "datetime") {
        const newDate = new Date(change.newCell.date);
        if (newDate >= today) {
          dataRow[fieldName] = newDate.toISOString();
        }
      }
      if (change.type === "WrappedText" || change.type === "text") {
        let newText = change.newCell.text.trim();

        if (newText.length > 75) {
          newText = newText.substring(0, 75);
        }

        newText = newText.replace(/^\w/, (c) => c.toUpperCase());

        if (newText !== change.previousCell.text) {
          prevEvents[eventIndex - 1][fieldName] = newText;
        }
      }
    });
    return [...prevEvents];
  };

  const handleChanges = (changes) => {
    setEvents((prevEvents) => applyChanges(changes, prevEvents));
  };

  const handleWheelScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    if (isHoveringGrid && gridRef.current) {
      event.preventDefault();
      gridRef.current.scrollLeft += event.deltaY;
    }
  };

  const { fieldMap } = getValues();
  const { columns, maxWidths } = getColumns(events);

  const rows =
    fileType === "csv"
      ? getCsvRows(events, fieldMap, maxWidths)
      : getIcsRows(events, fieldMap, maxWidths);

  return (
    <>
      <div
        ref={gridRef}
        style={{
          maxWidth: "100vw",
          overflowX: "auto",
          overflowY: "hidden",
          height: "80vh",
          whiteSpace: "nowrap"
        }}
        onWheel={handleWheelScroll}
      >
        <div
          onMouseEnter={() => {
            setIsHoveringGrid(true);
            document.body.style.overflow = "hidden";
          }}
          onMouseLeave={() => {
            setIsHoveringGrid(false);
            document.body.style.overflow = "";
          }}
        >
          <ReactGrid
            rows={rows}
            columns={columns}
            stickyLeftColumns={2}
            onCellsChanged={handleChanges}
            customCellTemplates={{
              customHeader: new BasicTextTemplate(),
              WrappedText: new WrappedTextTemplate(),
              location: new LocationTemplate(),
              datetime: new DateTimeTemplate()
            }}
          />
        </div>
      </div>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={fileType === "csv" ? generateCsvToCSV : generateIcsToCSV}
        saveBtnLabel="Continue"
        isDisabled={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate(`/calendar`)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </>
  );
};
